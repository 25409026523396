import { createStore } from 'vuex'
const store = createStore({
  state() {
    return {
      stations : {},
      userData : false, // firebase userData
      userInit : null,
      discover : {},
      charts : false, // top chart
      playerMetadata : false,
      playerActiveId : false, // station active ID
      playerStatus : false,
      index : {}, // index object
      avatarOptions : {
        base64 : true,
        width : 100,
        height : 100,
        radius:50,
        colorful:true,
        // margin:5
      },
      mystationList : null, // mystation > main.vue page
      myavatar : require('@/assets/images/noavatar.png'),
      routeData : false,
      header : {
        name : "",
        logo : ""
      }
    }
  },
  mutations: {
    setAuthState(state,auth) {
      state.userInit = true
      state.userData = auth
    },
    setStationList(state,data) {
      state.stationList =data 
    },

  },
  getters: {
    isAuthenticated: state => {
      return state.count
    }
  }
})
export default store
