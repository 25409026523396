<template>
<div class="container-fluid pb-0 px-0">
    <div class="row">
        <div class="col-12">
            <ul class="nav nav-tabs pb-0 mb-0" id="genreMenu" >
                <swiper @swiper="onSwipeGenre" @slideChangeTransitionStart="onMenuSwipe" :breakpoints="breakpointsGenre" :centeredSlides="true" :centeredSlidesBounds="true" :normalizeSlideIndex="false">
                    <swiper-slide v-for='(l,id,index) in tabData' :data-id="id">
                        <li class="nav-item">
                            <a class="nav-link text-center" data-toggle="pill" href="#pills-home" @click="onMenuSwipe(id,index,'click')" style="white-space: nowrap;">{{ l }}</a>
                        </li>
                    </swiper-slide>
                </swiper>
            </ul>
        </div>
        <div class="col-12" id="swiperGenreContainer" >
            <div class="iq-card">
                <div class="iq-card-body mb-0 pb-0">
                    <div class="index-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for='(l,id) in tabData' :data-id="id">
                                <template v-if="$store.state.stations && $store.state.stations[id]">
                                    <ul class="list-unstyled row iq-box-hover mb-0">
                                        <li class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4 iq-music-box" v-for='stationData in $store.state.stations[id]'>
                                            <div class="iq-card border-0">
                                                <div class="iq-card-body p-0">
                                                    <div class="iq-thumb">
                                                        <router-link :to="'/s/'+stationData.id">
                                                            <img :src="stationData.logo" class="img-border-radius img-fluid w-100" alt="">
                                                        </router-link>
                                                    </div>
                                                    <div class="text-truncate text-center px-0 mt-2">
                                                        <h6 class="font-weight-600 mb-0 text-truncate">{{ stationData.name }}</h6>
                                                        <p class="mb-0 text-truncate text-muted" v-if="id==='trending'"><small>ผู้ฟัง {{ stationData.listeners }} คน</small></p>
                                                        <p class="mb-0 text-truncate text-muted" v-if="id==='newstations'"><small>{{ genre[stationData.genre ]}}</small></p>
                                                        <p class="mb-0 text-truncate text-muted" v-else><small>{{ stationData.des }}</small></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </template>
                                <template v-else-if="$store.state.stations[id] === false">
                                    <div class="text-center py-4">
                                        <h2>ไม่พบสถานี{{ tabData[id] }}</h2>
                                        <p>หากท่านเป็นเจ้าของสถานีหรือผู้เกี่ยวข้อง กรุณาคลิกที่ปุ่มด้านล่างเพื่อเพิ่มสถานีของท่านเข้ามายังแพลตฟอร์มของเรา</p>
                                        <router-link to="/enroll" class="btn bg-primary btn-lg"><i class="fal fa-plus"></i> เพิ่มสถานี</router-link>
                                    </div>
                                </template>
                                <template v-else-if="tabActive === id">
                                    <ul class="list-unstyled row iq-box-hover mb-0">
                                        <li class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4 iq-music-box" v-for='(n, i) in 6'>
                                            <div class="iq-card">
                                                <div class="iq-card-body p-0">
                                                <div class="iq-thumb">
                                                    <skeleton-image class="img-border-radius img-fluid w-100" effect="blink"/>
                                                </div>
                                                <div class="text-center px-0 mt-2">
                                                    <h6 class="font-weight-600 mb-0"><skeleton-text effect="blink">XXXXXXX</skeleton-text></h6>
                                                    <p class="mb-0 text-truncate"><skeleton-text effect="blink">XXXXXX</skeleton-text></p>
                                                </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="iq-card">
                <div class="iq-card-header d-flex justify-content-between">
                    <div class="iq-header-title">
                        <h4 class="card-title">เพลงฮิตติดชาร์ต</h4>
                    </div>
                    <div class="d-flex align-items-center iq-view">
                        <b class="mb-0 text-primary"><router-link to="/charts">ดูทั้งหมด <i class="las la-angle-right"></i></router-link></b>
                    </div>
                </div>
                <div class="iq-card-body pb-0">
                    <ul class="list-unstyled row iq-box-hover mb-0" v-if="$store.state.index.charts">
                        <li class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4" v-for='(l,id) in $store.state.index.charts'>
                            <div class="iq-card mb-0 border-0">
                                <div class="iq-card-body p-0 ">
                                <div class="iq-thumb">
                                    <div class="iq-music-overlay"></div>
                                    <a href="javascript:void(0)" >
                                        <img :src='l.attributes.artwork.url.replace(/({w}|{h})/g,"100")' class="img-border-radius img-fluid w-100" alt="">
                                    </a>
                                    <div class="overlay-music-icon" @click="playmusic(id)">
                                        <a href="javascript:void(0)">
                                            <i class="fal fa-play-circle"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="feature-list text-center" @click="playmusic(id)">
                                    <h6 class="font-weight-600  mb-0">{{ l.attributes.name }}</h6>
                                    <p class="mb-0 text-truncate">{{ l.attributes.artistName }}</p>
                                </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div> 
    </div>
</div>
</template>
<style>
ul#genreMenu  li > a
{
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;

}
ul#genreMenu  li > a.active
{
    overflow: visible !important;
    z-index: 100000 !important;
    opacity: 1 !important;
    background: var(--iq-danger);
    color:white;
}
</style>
<script>
import { SkeletonBlock,SkeletonImage,SkeletonText,SkeletonAvatar } from 'skeleton-elements/vue';
import 'skeleton-elements/skeleton-elements.css';
import genre from '@/ecosystem/genre'

import SwiperJS from 'swiper/bundle';
// import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import {indexTab} from '@/ecosystem/config'
import firebase from "firebase/app"
import "firebase/database";
import "firebase/firestore";
export default {
    components: {
        Swiper,
        SwiperSlide,
        SkeletonBlock,SkeletonImage,SkeletonText,SkeletonAvatar
    },
    data() {
        return {
            tabActive : null,
            swiperCon : null,
            tabData : indexTab(),
            genre : genre,
            genreSwiper : null,
            topchart : false,
            breakpointsGenre: {
                0: {
                slidesPerView: 2,
                spaceBetween: 20
                },
                320: {
                slidesPerView: 3,
                spaceBetween: 20
                },
                480: {
                slidesPerView: 4,
                spaceBetween: 20
                },
                768: {
                slidesPerView: 5,
                spaceBetween: 30
                },
                992: {
                slidesPerView: 6,
                spaceBetween: 40
                },
                1200: {
                slidesPerView: 7,
                spaceBetween: 40
                }
            }
        }
    },
    methods: {
        onSwipeGenre(swiper) {
            this.genreSwiper = swiper;
            setTimeout(function(){swiper.slideTo(0)},100)
        },
        playmusic(id) {
            let l = this.$store.state.index.charts[id]
            this.$store.djfnc.musicAction('play',{url : l.attributes.previews[0].url , image : l.attributes.artwork.url.replace(/({w}|{h})/g,'60') })
            this.$store.state.playerMetadata = {
                servertitle : l.attributes.artistName,
                songtitle : l.attributes.name
            }
        },
        async onMenuSwipe(swiper,index,action=null)
        {
            let vue = this
            let id = null , activeEl =null , menuIndex = null
            if(typeof swiper === "object")
            {
                menuIndex = swiper.activeIndex
                activeEl = swiper.slides[swiper.activeIndex]
                id = $(activeEl).data("id")
            }
            else
            {
                id = swiper
                menuIndex = index
            }

            this.tabActive = id
            this.swiperCon.slideTo(menuIndex)
            $("#genreMenu a").removeClass("active")
            $(activeEl).find("a").addClass("active")
            this.swiperCon.updateAutoHeight(50)

            if(action == null)
            {
                if(typeof this.$store.state.stations[id] === "undefined")
                {
                    let data = await $.ajax(`https://djinth.firebaseio.com/api/discover/${id}.json`)
                    if(!data) this.$store.state.stations[id] = false
                    else this.$store.state.stations[id] = data
                    setTimeout(function(){vue.swiperCon.updateAutoHeight(50); },500)
                }

                /// preload next id
                let arr = Object.keys(this.tabData)
                let checkIndex = arr.indexOf(id)
                let nextIndex = checkIndex+1 
                let nextId = arr[nextIndex]
                if(typeof this.$store.state.stations[nextId] === "undefined" && nextIndex < arr.length)
                {
                    let data2 = await $.ajax(`https://djinth.firebaseio.com/api/discover/${nextId}.json`)
                    if(!data2) this.$store.state.stations[nextId] = false
                    else this.$store.state.stations[nextId] = data2
                    console.log("preload done",nextId)
                }
                $("html, body").animate({ scrollTop: 0 }, "slow");
            }
        }
    },
    mounted(){
        let vue = this
        $.get(`https://djinth.firebaseio.com/api/index.json`,function(data){
            vue.$store.state.index = data
        },"json")
        this.swiperCon = new SwiperJS('.index-container',{
            observer : true,
            observeParents : true,
            observeSlideChildren : true,
            spaceBetween: 100,
            autoHeight:true,
            on: {
                slideChange: function (swiper) {
                    vue.genreSwiper.slideTo(swiper.activeIndex,1000)
                },
            }
        })
    }
};
</script>