export function streamType() {
    return {
        "sc1": "ShoutCast 1",
        "sc2": "ShoutCast 2",
        "ic2": "IceCast 2",
    }
}
export function indexTab() {
    return {
        "trending": "สถานีมาแรง",
        "newstations": "สถานีใหม่",
        "all": "ทุกแนวเพลง",
        "hit": "เพลงฮิต",
        "inter": "เพลงสากล",
        "new": "เพลงใหม่",
        "old": "เพลงเก่า",
        "esan": "เพลงอีสาน",
        "life": "เพลงเพื่อชีวิต",
        "looktung": "เพลงลูกทุ่ง",
        "cult": "ศาสนา",
        "news": "ข่าว",
    }
}

export default {streamType,indexTab}

