<template>
<!-- <div id="loading">
  <div id="loading-center">
  </div>
</div> -->

<body>
    <div class="wrapper">
        <Gheader />
        <div id="content-page" class="content-page">
            <div id="loginModal">
                <Login />
            </div>
            <router-view :key="$route.path"></router-view>
        </div>
    </div>
    <Gfooter />
</body>
</template>

<script>
import Gheader from '@/views/common/header'
import Gfooter from '@/views/common/footer'
import Login from '@/views/auth/login'
import genre from '@/ecosystem/genre'
const Swal = require('sweetalert2')

import firebase from "firebase/app"
import "firebase/auth";
import "firebase/database";

import MicroModal from 'micromodal';

import 'remixicon/fonts/remixicon.css'
import "bootstrap/dist/js/bootstrap.min.js";

import '@sweetalert2/themes/borderless/borderless.scss';

export default {

    components: {
        Gheader,
        Gfooter,
        Login
    },
    // beforeCreate() {
    //     if (process.env.NODE_ENV !== "development" && window.location.hostname !== "localhost" && location.protocol !== 'https:') {
    //         location.replace(`https:${location.href.substring(location.protocol.length)}`);
    //     }
    // },
    mounted() {
        let vue = this
        firebase.auth().onAuthStateChanged((user) => {
            $("#loading").delay().fadeOut("");
            this.$store.commit('setAuthState', user)
        })
        jQuery(document).on('click', function (e) {
            let myTargetElement = e.target;
            let selector, mainElement;
            if (jQuery(myTargetElement).hasClass('search-toggle') || jQuery(myTargetElement).parent().hasClass('search-toggle') || jQuery(myTargetElement).parent().parent().hasClass('search-toggle')) {
                if (jQuery(myTargetElement).hasClass('search-toggle')) {
                    selector = jQuery(myTargetElement).parent();
                    mainElement = jQuery(myTargetElement);
                } else if (jQuery(myTargetElement).parent().hasClass('search-toggle')) {
                    selector = jQuery(myTargetElement).parent().parent();
                    mainElement = jQuery(myTargetElement).parent();
                } else if (jQuery(myTargetElement).parent().parent().hasClass('search-toggle')) {
                    selector = jQuery(myTargetElement).parent().parent().parent();
                    mainElement = jQuery(myTargetElement).parent().parent();
                }
                if (!mainElement.hasClass('active') && jQuery(".navbar-list li").find('.active')) {
                    jQuery('.navbar-list li').removeClass('iq-show');
                    jQuery('.navbar-list li .search-toggle').removeClass('active');
                }

                selector.toggleClass('iq-show');
                mainElement.toggleClass('active')

                e.preventDefault();
            } else if (jQuery(myTargetElement).is('.search-input')) {} else {
                jQuery('.navbar-list li').removeClass('iq-show');
                jQuery('.navbar-list li .search-toggle').removeClass('active');
            }
        });
        $(document).on('click', '.wrapper-menu', function () {
            jQuery(this).toggleClass('open');
            jQuery("body").toggleClass("sidebar-main");
            $("#stationgenre").collapse('hide')
        })
        $("#iq-sidebar-toggle a[href^='/']").click(function () {
            if ($(window).width() < 1300) {
                jQuery('.wrapper-menu').toggleClass('open');
                jQuery("body").toggleClass("sidebar-main");
                $("#stationgenre").collapse('hide')
            }
        })
        /// เมนู ACTIVE  START
        // $(document).on("click", '.iq-menu > li > a', function() {
        //     $('.iq-menu > li > a').parent().removeClass('active');
        //     $('#stationgenre > li > a').parent().removeClass('active');
        //     $(this).parent().addClass('active');
        // });

        // $(document).on("click", '#stationgenre > li > a', function() {
        //     $('#stationgenre > li > a').parent().removeClass('active');
        //     $('.iq-menu > li > a').parent().removeClass('active');
        //     $("a[href='#stationgenre']").parent().addClass('active')
        //     $(this).parent().addClass('active');
        // });
        $("#stationgenre").collapse('show')
        // if(window.location.pathname.match(/^\/discover/))
        // {
        //   $("a[href='#stationgenre']").parent().addClass('active')
        //   $(`#stationgenre > li > a[href='${window.location.pathname}']`).parent().addClass('active')
        // }
        // else
        // {
        //   $('.iq-menu > li > a').parent().removeClass('active')
        //   $(`.iq-menu > li > a[href='${window.location.pathname}']`).parent().addClass('active')
        // }
        /// เมนู ACTIVE END
    }
}
</script>

<style src="@/assets/css/bootstrap.min.css"></style>
<style src="@/assets/fontawesome/css/all.css"></style>
<style src="@/assets/css/typography.css"></style>
<style src="@/assets/css/style.css"></style>
<style src="@/assets/css/responsive.css"></style>
<style src="@/assets/css/modal.css"></style>
<style>
.none {
    display: none;
}
</style>
