<template>
<div class="modal micromodal-slide" id="modal-login" aria-hidden="true">
    <div class="modal__overlay " tabindex="-1">
        <div id="recaptcha-container"></div>
        <div class="row justify-content-center align-items-center w-100" style="padding-top:50px">

            <div class="col-lg-6 col-md-9 col-sm-12 col-12 align-self-center">

                <div class="sign-user_card bg-dark">
                    <div class="d-flex justify-content-center ">
                        <div class="sign-user_logo">
                            <img src="@/assets/images/logodj.png" class="rounded-0 img-fluid border-0" v-if="!$store.state.userData">
                            <img :src="$store.state.myavatar" class="rounded-0 img-fluid border-0 bg-white rounded-circle" v-else>
                        </div>
                    </div>
                    <div class="sign-in-page-data">
                        <button class="modal__close text-white" @click="loginClose"><i class="fas fa-times"></i></button>
                        <div class="sign-in-from w-100 pt-5 m-auto">
                            <div class="login-main" v-if="!$store.state.userData">
                                <h3 class="text-center text-white mt-3 mb-0">เข้าสู่ระบบ</h3>
                                <div class="text-center" id="text-phone">กรอกเบอร์โทรศัพท์เพื่อเข้าสู่ระบบ</div>
                                <div class="text-center none" id="text-otp">กรุณาใส่รหัส OTP ที่ระบบส่งไปที่เบอร์<br><span id="text-otp-number"></span> <span @click="changeNumber" class="text-warning">(เปลี่ยนหมายเลข)</span></div>

                                <form class="text-center digit-group mt-3" method="get" data-group-name="digits" data-autosubmit="false" autocomplete="off" id="form_phoneNumber">
                                    <input type="tel" id="digit-1" name="digit-1" data-next="digit-2" />
                                    <input type="tel" id="digit-2" name="digit-2" data-next="digit-3" data-previous="digit-1" />
                                    <input type="tel" id="digit-3" name="digit-3" data-next="digit-4" data-previous="digit-2" />
                                    <span class="splitter d-none d-sm-inline-block">&ndash;</span>
                                    <input type="tel" id="digit-4" name="digit-4" data-next="digit-5" data-previous="digit-3" />
                                    <input type="tel" id="digit-5" name="digit-5" data-next="digit-6" data-previous="digit-4" />
                                    <input type="tel" id="digit-6" name="digit-6" data-next="digit-7" data-previous="digit-5" />
                                    <span class="splitter d-none d-sm-inline-block">&ndash;</span>
                                    <input type="tel" id="digit-7" name="digit-7" data-next="digit-8" data-previous="digit-6" />
                                    <input type="tel" id="digit-8" name="digit-8" data-next="digit-9" data-previous="digit-7" />
                                    <input type="tel" id="digit-9" name="digit-9" data-next="digit-10" data-previous="digit-8" />
                                    <input type="tel" id="digit-10" name="digit-10" data-previous="digit-9">
                                    <input type="hidden" id="sign-in-form">
                                </form>

                                <form class="text-center digit-group mt-3 none" method="get" data-group-name="digits" data-autosubmit="false" autocomplete="off" id="form_OTP">
                                    <input type="tel" id="otp-1" name="otp-1" data-next="otp-2" />
                                    <input type="tel" id="otp-2" name="otp-2" data-next="otp-3" data-previous="otp-1" />
                                    <input type="tel" id="otp-3" name="otp-3" data-next="otp-4" data-previous="otp-2" />
                                    <input type="tel" id="otp-4" name="otp-4" data-next="otp-5" data-previous="otp-3" />
                                    <input type="tel" id="otp-5" name="otp-5" data-next="otp-6" data-previous="otp-4" />
                                    <input type="tel" id="otp-6" name="otp-6" data-previous="otp-5" />
                                </form>
                            </div>
                            <div class="login-inform mt-3 " v-else-if="$route.query.ref">
                                <h6 class="text-white">โปรดรอสักครู่...</h6>
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated" style="width: 100%"></div>
                                </div>

                            </div>
                            <div class="login-inform mt-3" v-else>
                                <!-- <h3 class="text-white mt-3 mb-0">ข้อมูลส่วนตัว</h3> -->
                                <div class="form-group">
                                    <h5 class="text-primary">เบอร์โทรศัพท์</h5>
                                    <input type="email" class="form-control" :value="$store.state.userData.phoneNumber" readonly>
                                </div>
                                <div class="form-group">
                                    <h5 class="text-primary mb-0 pb-0">ชื่อเล่น</h5>
                                    <input type="email" class="form-control bg-white" v-model="nickname" @keyup="avatarChange" placeholder="กรอกข้อมูลที่นี่" maxlength="20">
                                </div>
                                <button class="btn btn-block btn-primary" @click="nicknameSet" :disabled='isLoad'>บันทึกข้อมูล</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="alert text-white bg-primary" role="alert" v-if="error">
                    <div class="iq-alert-icon">
                        <i class="ri-alert-line"></i>
                    </div>
                    <div class="iq-alert-text">{{ error }}</div>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Swal from 'sweetalert2'
import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/functions";

import 'busy-load';
require('jquery-serializejson')

import Avatars from '@dicebear/avatars';
import sprites from '@dicebear/avatars-bottts-sprites';
export default {
    name: 'app',
    data() {
        return {
            confirmationResult: false,
            recaptchaVerifier: false,
            error: false,
            nickname: this.$store.state.userData.displayName,
            avatar: null,
            isLoad: false
        }
    },
    watch: {
        '$store.state.userData': function (data) {
            if (data && data.displayName) {
                this.nickname = data.displayName
                this.avatarChange()
                $(".search_list").css("right", "90px") // ช่อง search เวลาเป็นสมาชิกกับไม่เป็นสมาชิก right ไม่เท่ากัน
                this.createToken()

            } else {
                $(".search_list").css("right", "130px") // ช่อง search เวลาเป็นสมาชิกกับไม่เป็นสมาชิก right ไม่เท่ากัน
            }
        },
        '$route.query.token': async function (token) {
            if (token !== "success") {
                await firebase.auth().signInWithCustomToken(token)
                window.location.href = "https://" + window.location.hostname
            }
        },
    },
    mounted() {
        let $vm = this;
        this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-form', {
            'size': 'invisible'
        });
        $('#form_phoneNumber input*,#form_OTP input*').attr('maxlength', 1).on("keyup", function (e) {
            var parent = $($(this).parent());
            let keycode = event.target.value.charAt(event.target.selectionStart - 1).charCodeAt()
            if (/\D/g.test(this.value)) // non number input block
                this.value = this.value.replace(/\D/g, '');
            else if (!keycode)
            {
                var prev = parent.find('input#' + $(this).data('previous'));
                if (prev.length) {$(prev).select()}
            }
            else 
            {
                var next = parent.find('input#' + $(this).data('next'));
                if (next.length) {
                    $(next).select();
                } else if (this.id == "digit-10") {
                    let formdata = $('form#form_phoneNumber').serializeJSON();
                    let phoneNumber = ""
                    $.each(formdata, function (id, val) {
                        phoneNumber += val
                    })
                    $vm.requestOTP(phoneNumber)
                } else if (this.id == "otp-6") {
                    let formdata = $('form#form_OTP').serializeJSON();
                    let otp = ""
                    $.each(formdata, function (id, val) {
                        otp += val
                    })
                    $vm.confirmOTP(otp)
                }
            }
        });
    },
    methods: {
        avatarChange() {
            let avatars = new Avatars(sprites, this.$store.state.avatarOptions);
            this.$store.state.myavatar = avatars.create(this.nickname);
        },
        loginClose() {
            MicroModal.close('modal-login')
        },
        async nicknameSet(e) {
            e.preventDefault()
            this.isLoad = true
            let vue = this
            let name = this.nickname
            let userData = this.$store.state.userData
            if (userData) {
                await userData.updateProfile({
                    displayName: name
                }).then(function () {
                    window.location.reload()
                }).catch(function (error) {
                    vue.error = error.message
                    this.isLoad = false
                })
            }
        },
        async requestOTP(phoneNumber) {
            let $vm = this
            $vm.error = false
            if (!phoneNumber.match(/^[0]{1}[0-9]{9}$/))
                $vm.error = "เบอร์โทรศัพท์ไม่ถูกต้อง"
            else {
                this.loadshow(true, false, ".sign-in-from")
                $('form#form_phoneNumber input').attr("readonly", "readonly")
                await firebase.auth().signInWithPhoneNumber(`+66${phoneNumber}`, this.recaptchaVerifier).then(function (confirmationResult) {

                    $('form#form_phoneNumber,#text-phone').hide()
                    $('form#form_OTP,#text-otp').show()
                    $("#text-otp-number").text(phoneNumber)
                    $vm.confirmationResult = confirmationResult;
                }).catch(function (error) {
                    $vm.error = error.message
                });
                $('form#form_phoneNumber input').removeAttr("readonly")
                this.loadshow(false, false, ".sign-in-from")
            }
        },
        async confirmOTP(otp) {
            let $vm = this
            $vm.error = false
            let credential = firebase.auth.PhoneAuthProvider.credential(this.confirmationResult.verificationId, otp);
            if (!otp.match(/^[0-9]{6}$/)) {
                $vm.error = "หมายเลข OTP ไม่ถูกต้อง"
                $('form#form_OTP input').val("")
            } else {
                this.loadshow(true, false, ".sign-in-from")
                $('form#form_OTP input').attr("readonly", "readonly")
                await firebase.auth().signInWithCredential(credential).then(usercred => {
                    if (usercred.user.displayName && !$vm.$route.query.ref)
                        this.loginClose()
                    else
                        this.createToken()
                }, error => {
                    let message
                    if (error.code === "auth/invalid-verification-code")
                        message = "รหัส OTP ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง"
                    else if (error.code === "auth/credential-already-in-use")
                        message = "ท่านเคยลงทะเบียนด้วยเบอร์โทรศัพท์นี้ไปแล้ว กรุณาเข้าสู่ระบบด้วยเบอร์โทรศัพท์อีกครั้ง"
                    else
                        message = error.message

                    console.log(error)
                    $vm.error = message
                    $('form#form_OTP input').removeAttr("readonly", "readonly")
                    $('form#form_OTP input').val("")
                })
                this.loadshow(false, false, ".sign-in-from")
            }
        },
        changeNumber() {
            $('form#form_phoneNumber,#text-phone').show()
            $('form#form_phoneNumber input,form#form_OTP input').val("")
            $('form#form_OTP,#text-otp').hide()
            $('form#form_OTP input').removeAttr("readonly", "readonly")
        },
        createToken() {
            let ref = this.$route.query.ref
            let vue = this
            if (ref) {
                firebase.app().functions('asia-east2').httpsCallable('createToken')({}).then(result => {
                    window.location.href = `https://${ref}?token=` + result.data
                }).catch(error => {
                    vue.error = error.message
                })
            }
        }
    }

}
</script>

<style>
.digit-group input {
    width: 30px;
    height: 40px;
    border: none;
    text-align: center;
    font-size: 18px;
    font-weight: 200;
    margin: 0 2px;
}

.digit-group .splitter {
    padding: 0 5px;
    color: white;
    font-size: 24px;
}

@media(max-width:479px) {
    .digit-group input {
        width: 23px;
        height: 30px;
        font-size: 14px;

    }

}

@media(max-width:360px) {
    .digit-group input {
        width: 18px;
        height: 25px;
        font-size: 14px;
    }
}
</style>
