<template>
<div class="iq-sidebar">
    <div class="iq-sidebar-logo d-flex justify-content-between">
        <router-link to="/" class="header-logo">
            <img src="@/assets/images/logodj.png" class="img-fluid rounded-normal pl-1" alt="">
            <div class="logo-title main-color">

                <span class="text-uppercase">STREAMING</span>
            </div>
        </router-link>
        <div class="iq-menu-bt-sidebar">
            <div class="iq-menu-bt align-self-center">
                <div class="wrapper-menu open pt-2">
                    <div class="main-circle"><i class="las la-bars"></i></div>
                </div>
            </div>
        </div>
    </div>
    <div id="sidebar-scrollbar">
        <nav class="iq-sidebar-menu">
            <ul id="iq-sidebar-toggle" class="iq-menu">
                <li>
                    <router-link to="/" class="iq-waves-effect"><i class="far fa-home"></i><span>หน้าแรก</span></router-link>
                </li>
                <li>
                    <router-link to="/charts" class="iq-waves-effect"><i class="far fa-chart-line"></i><span>ชาร์ตเพลง</span></router-link>
                </li>
                <li>
                    <a href="#stationgenre" class="iq-waves-effect" data-toggle="collapse">
                        <i class="far fa-headphones"></i>
                        <span>แนวเพลง</span>
                        <i class="ri-arrow-right-s-line iq-arrow-right"></i>
                    </a>
                    <ul id="stationgenre" class="iq-submenu collapse">
                        <li v-for='(l,id) in genre'>
                            <router-link :to="'/discover/'+id">{{ l }}</router-link>
                        </li>
                    </ul>
                </li>
                <li>
                    <router-link to="/enroll" class="iq-waves-effect"><i class="far fa-plus"></i><span>เพิ่มสถานีใหม่</span></router-link>
                </li>
                <li>
                    <router-link to="/contactus" class="iq-waves-effect"><i class="far fa-envelope"></i><span>ติดต่อเรา</span></router-link>
                </li>
            </ul>
        </nav>
    </div>
</div>
<!-- TOP Nav Bar -->
<div class="iq-top-navbar">
    <div class="iq-navbar-custom mt-md-0 mt-2">
        <nav class="navbar navbar-expand navbar-light p-0">
            <div class="iq-menu-bt d-flex align-items-center mt-md-2" style="max-width:80%">
                <div class="wrapper-menu">
                    <div class="main-circle"><i class="las la-bars"></i></div>
                </div>
                <div class="iq-navbar-logo d-flex justify-content-between text-truncate">
                    <!-- โซนมือถือ -->
                    <template v-if="$route.name==='stationview' && $store.state.header">
                        <router-link to="/" class="header-logo">
                            <div class="pt-1 logo-title">
                                <span class="text-default text-uppercase">{{ $store.state.header.name }}</span>
                            </div>
                        </router-link>
                    </template>
                    <template v-if="$route.name!=='stationview'">
                        <div class="d-none d-md-block">
                            <router-link to="/" class="header-logo">
                                <img src="@/assets/images/logodj.png" class="img-fluid" alt="">
                                <div class="pt-2 pl-2 logo-title">
                                    <span class="text-default text-uppercase">Streaming</span>
                                </div>
                            </router-link>
                        </div>
                    </template>
                </div>
            </div>
            <div class="collapse navbar-collapse">
                <ul class="list-unstyled iq-menu-top d-flex justify-content-between mb-0 p-0 mt-2">
                    <li>
                        <router-link to="/">หน้าแรก</router-link>
                    </li>
                    <li>
                        <router-link to="/charts">ชาร์ตเพลงฮิต</router-link>
                    </li>
                    <li>
                        <router-link to="/discoverall">สถานีทั้งหมด</router-link>
                    </li>

                </ul>
                <ul class="navbar-nav ml-auto navbar-list">
                    <li class="nav-item nav-icon searchResp" :style="style">
                        <div class="iq-search-bar mt-3 mt-md-2 mt-lg-3 px-0">
                            <div class="autoComplete_wrapper">
                            <input id="autoComplete"  type="search" dir="ltr" spellcheck=false autocorrect="off" autocomplete="off" autocapitalize="off">
                            </div>
                        </div>
                    </li>
                    <template v-if="$store.state.userData !== null">
                        <li class="mt-0 mt-sm-1 mt-md-0 mt-lg-2">
                            <a href="#" class="search-toggle iq-waves-effect d-flex align-items-center pr-0">
                                <img :src="$store.state.myavatar" class="img-fluid rounded-circle">
                            </a>
                            <div class="iq-sub-dropdown iq-user-dropdown">
                                <div class="iq-card shadow-none m-0">
                                    <div class="iq-card-body p-0 ">
                                        <div class="bg-primary p-3">
                                            <h5 class="mb-0 text-white line-height">ยินดีต้อนรับท่านสมาชิก</h5>
                                            <span class="text-white font-size-12">{{ $store.state.userData.phoneNumber }}</span>
                                        </div>
                                        <router-link to="/mystation" class="iq-sub-card iq-bg-primary-hover">
                                            <div class="media align-items-center">
                                                <div class="rounded iq-card-icon iq-bg-primary">
                                                    <i class="ri-settings-2-line"></i>
                                                </div>
                                                <div class="media-body ml-3">
                                                    <h6 class="mb-0">จัดการสถานี</h6>
                                                    <p class="mb-0 font-size-12">ดูข้อมูลและจัดการสถานีของท่านได้ที่นี่</p>
                                                </div>
                                            </div>
                                        </router-link>
                                        <a href="javascript: void(0)" class="iq-sub-card iq-bg-primary-hover" @click="loginshow">
                                            <div class="media align-items-center">
                                                <div class="rounded iq-card-icon iq-bg-primary">
                                                    <i class="ri-account-box-line"></i>
                                                </div>
                                                <div class="media-body ml-3">
                                                    <h6 class="mb-0 ">ข้อมูลส่วนตัว</h6>
                                                    <p class="mb-0 font-size-12">ตรวจสอบและแก้ไขข้อมูลส่วนตัว</p>
                                                </div>
                                            </div>
                                        </a>
                                        <router-link to="/client/billing" class="iq-sub-card iq-bg-primary-hover">
                                            <div class="media align-items-center">
                                                <div class="rounded iq-card-icon iq-bg-primary">
                                                    <i class="fal fa-sack-dollar"></i>
                                                </div>
                                                <div class="media-body ml-3">
                                                    <h6 class="mb-0 ">การเงิน</h6>
                                                    <p class="mb-0 font-size-12">เติมเงิน / ดูประวัติการใช้จ่าย</p>
                                                </div>
                                            </div>
                                        </router-link>
                                        <router-link to="/enroll" class="iq-sub-card iq-bg-primary-hover">
                                            <div class="media align-items-center">
                                                <div class="rounded iq-card-icon iq-bg-primary">
                                                    <i class="ri-add-line"></i>
                                                </div>
                                                <div class="media-body ml-3">
                                                    <h6 class="mb-0 ">เพิ่มสถานีใหม่</h6>
                                                    <p class="mb-0 font-size-12">ติดต่อลงสถานีบนเว็บได้ที่นี่</p>
                                                </div>
                                            </div>
                                        </router-link>
                                        <div class="d-inline-block w-100 text-center p-3">
                                            <button class="bg-primary iq-sign-btn" @click="logout">ออกจากระบบ <i class="fad fa-sign-out"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </template>
                    <template v-else>
                        <li class="mt-md-2 mt-lg-3 font-weight-bold"  @click="loginshow"><a href="javascript: void(0)">
                            <i class="fal fa-sign-in fa-1x pt-1" style="font-size:30px"></i>
                        </a></li>
                        
                    </template>

                </ul>
            </div>
        </nav>
    </div>
</div>
</template>


<script>
import firebase from "firebase/app";
import genre from '@/ecosystem/genre'
import autoComplete from "@tarekraafat/autocomplete.js";
import "@tarekraafat/autocomplete.js/dist/css/autoComplete.css";
export default {

    data() {
        return {
            genre: genre,
        }
    },
    mounted() {
        let vue = this
        new autoComplete({
            data: {
                src: async () => {
                    const source = await fetch("https://djinth.firebaseio.com/api/search.json");
                    const data = await source.json();
                    return data;
                },
                keys: ["n"],
                cache: true
            },
            placeHolder: "ค้นหาสถานี", // Placeholder text for the search input
            selector: "#autoComplete", // Selector for the search input
            threshold: 0, // Minimum characters length before showing results
            debounce: 0, // Wait time in ms after the last keystroke, before searching
            resultsList: {
                render: true,
                element: (list, data) => {
                    // Remove previous search results
                    if(!data.results.length) {
                        const message = document.createElement("div");
                        message.setAttribute("class", "no_result");
                        // Add message text content
                        message.innerHTML = `<div style="padding:10px">ไม่พบสถานี "${data.query}"</div>`;
                        // Add message list element to the list
                        list.prepend(message);
                    }
                    // let searchList = document.querySelector("#search_list");
                    // if (searchList) {
                    //     searchList.parentNode.removeChild(searchList);
                    // }

                    // // Create a new list for this search
                    // searchList = document.createElement("ul");
                    // searchList.setAttribute("id", "search_list");
                    // searchList.setAttribute("class", "search_list");

                    // // Append the list below the autoComplete input
                    // document.querySelector("#autoComplete").insertAdjacentElement("afterend", searchList);

                    // // Populate the list with results
                    // data.results.forEach((item) => {
                    //     const resultItem = document.createElement("li");
                    //     resultItem.innerHTML = item.value.n; // Display the 'n' property
                    //     searchList.appendChild(resultItem);
                    // });
                },
                noResults: true
            },
            // onSelection: feedback => {
            //     // Handle the selection of an item from the autocomplete results
                // const selection = feedback.selection.value;
                // document.querySelector("#autoComplete").value = "";
                // document.querySelector("#autoComplete").setAttribute("placeholder", selection.n);
                
            //     // Navigate using Vue router to the selected item's detail page
            //     vue.$router.push({
            //         path: '/s/' + selection.i // Use 'i' property for the path
            //     });
            // },
        });

        $('#autoComplete').blur(function () {
            $("#search_list").css("display", "none")
        });
        $('#autoComplete').focus(function () {
            $("#search_list").css("display", "block")
        });

        document.querySelector("#autoComplete").addEventListener("selection", function (event) {
                       const selection = event.detail.selection.value;
                document.querySelector("#autoComplete").value = "";
                document.querySelector("#autoComplete").setAttribute("placeholder", selection.n);


            vue.$router.push({
                path: '/s/' + event.detail.selection.value.i 
            });

        });
    },
    methods: {
        loginshow() {
            if (window.location.hostname !== "dj.in.th" && this.$store.state.routeData) {
                window.location.href = `${window.domain}/s/${this.$store.state.routeData}?ref=${window.location.hostname}`
            } else
                MicroModal.show('modal-login')
        },
        logout() {

            firebase.auth().signOut().then(function () {
                window.location.reload()
            })
        }
    },
    computed: {
        style ()  {
            if(this.$route.name==='stationview')
                return {'--search-display': 'none'}
            else
                return {'--search-display': 'block'}
        }
    }
};
</script>

<style>
@media only screen and (max-width: 720px) {
   .searchResp
    {
       position:absolute !important;
       left:40px !important;
       top:-10px !important;
       display: var(--search-display);
       
    }
    .searchResp #autoComplete
    {
       width: 230px !important;

    }
    .searchResp #search_list
    {
        padding-top:5px;
        left: 20px;
        width: 260px !important;
    }
}

</style>