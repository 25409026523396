<template>
<footer class="iq-footer pl-3 px-sm-4" v-if="$store.state.playerStatus !== false">
    <div class="container-fluid px-0">
        <div class="player row align-items-center align-content-center ">
            <div class="col-auto">
                <div class="text-white px-0 px-sm-3 mt-1">
                    <i @click="musicAction('play')" class="fad fa-play-circle fa-3x" v-if="$store.state.playerStatus === 'stop'"></i>
                    <i class="fad fa-spinner-third fa-spin fa-2x" v-if="$store.state.playerStatus === 'load'"></i>
                    <i @click="musicAction('stop')" class="fad fa-stop fa-3x" v-if="$store.state.playerStatus === 'play'"></i>
                </div>
            </div>
            <div class="details col text-truncate px-0">
                <router-link :to="'/s/'+$store.state.playerActiveId"><img :src="playerData.image" class="rounded avatar-50 d-none d-md-block"></router-link>
                <div class="ml-3 text-truncate">
                    <div class="track-name text-truncate">{{ $store.state.playerMetadata.servertitle}}</div>
                    <div class="track-artist text-truncate">{{ $store.state.playerMetadata.songtitle}}</div>
                </div>
            </div>
            <div class="slider_container sound col-auto">
                <i class="fa fa-volume-down"></i>
                <input type="range" min="1" max="100" class="volume_slider" v-model="volume" @change="setVolume">
                <i class="fa fa-volume-up"></i>
            </div>
        </div>
    </div>
</footer>
</template>

<script>
import {
    Howl,
    Howler
} from '@catalogworks/howler';

export default {
    data() {
        return {
            volume: "80",
            playerData: {
                url: false,
                image: false,
            },
            howler: false,
            unload: false
        }
    },
    mounted() {
        let vue = this
        this.$store.djfnc = {
            musicAction(action, data) {
                vue.musicAction(action, data)
            },
        }
    },
    methods: {
        musicAction(action, data = {}) {
            let vue = this
            let player = this.playerData
            this.unload = false
            if (action === "play") {
                if (player.url === false) // ถ้าเล่นเพลงในขณะที่ยังไม่มีแถบตัวเล่นขึ้นมา
                {
                    if (!data.url || !data.image) {
                        console.log("Song data not found")
                        return false
                    }
                } else // ถ้าแถบตัวเล่นเพลงขึ้นมาแล้ว
                {
                    if (data.url) // ถ้ามีการส่ง url เพลงเข้ามาใหม่
                    {
                        if (data.url == player.url) // ถ้าส่ง url ซ้ำกับเพลงที่กำลังเล่นอยู่
                        {
                            return false
                        }
                    } else // ถ้าไม่มีการส่ง url เข้ามาใหม่ (กรณีนี้ ใช้สำหรับกดปุ่ม play pause)
                        data = player
                }
                // setup player value
                player.url = data.url
                player.image = data.image

                if (this.howler !== false) {
                    this.unload = true
                    this.howler.unload()
                }
                this.$store.state.playerStatus = "load"
                this.howler = new Howl({
                    src: [player.url],
                    format: ['mp3', 'aac'],
                    html5: true,
                    onloaderror: function () {
                        console.log("loaderror")
                        vue.$store.state.playerStatus = "stop"
                    },
                    onplayerror: function () {
                        console.log("play error")
                        vue.$store.state.playerStatus = "stop"
                    },
                    onplay: function () {
                        vue.howler.off('unlock')
                        console.log("on play")
                        vue.$store.state.playerStatus = "play"
                        if ('mediaSession' in navigator) {
                            navigator.mediaSession.metadata = new MediaMetadata({
                                title: vue.$store.state.playerMetadata.songtitle,
                                artist: vue.$store.state.playerMetadata.servertitle,
                                album: "ผู้ฟัง " + vue.$store.state.playerMetadata.listeners + " คน",
                                artwork: [{
                                        src: data.image,
                                        sizes: '96x96',
                                        type: 'image/png'
                                    },
                                    {
                                        src: data.image,
                                        sizes: '128x128',
                                        type: 'image/png'
                                    },
                                    {
                                        src: data.image,
                                        sizes: '192x192',
                                        type: 'image/png'
                                    },
                                    {
                                        src: data.image,
                                        sizes: '256x256',
                                        type: 'image/png'
                                    },
                                    {
                                        src: data.image,
                                        sizes: '384x384',
                                        type: 'image/png'
                                    },
                                    {
                                        src: data.image,
                                        sizes: '512x512',
                                        type: 'image/png'
                                    },
                                ]
                            });
                        }
                    },
                    onstop: function () {
                        if (vue.unload === true)
                            vue.$store.state.playerStatus = "load"
                        else
                            vue.$store.state.playerStatus = "stop"
                    },
                    onend: function () {
                        console.log("on end")
                        vue.$store.state.playerStatus = false
                    },
                    onunlock: function () {
                        setTimeout(function () {
                            if (vue.howler.playing() == false) {
                                vue.howler.play()
                            } else
                                console.log("use click play insted")
                        }, 1000)
                    }
                })
                if (this.howler.playing() == false)
                    this.howler.play()
            } else
                this.howler.stop()
        },
        setVolume(data) {
            Howler.volume(this.volume / 100)
        }
    }
}
</script>
