import { createRouter, createWebHistory } from 'vue-router'
import { trackRouter } from "vue-gtag-next";
import Home from '../views/index.vue'
import page404 from '../views/common/pages-error.vue'
import genre from '../ecosystem/genre'
import store from '../ecosystem/vuex'

const routes = [
  {
    path: '/',
    name: 'index', // ห้ามเปลี่ยนไม่งั้นพัง
    // component: Home,
    component: async () => {
      let hostname = window.location.hostname.replace(/[\.]/g,"-")
      if(hostname === "www-dj-in-th" || hostname === "dj-in-th")
        return import('../views/index.vue')
      else // กรณี subdomain
      {
        let routeData = await $.ajax({url: `https://djinth.firebaseio.com/api/route/${hostname}/id.json`})
        if(routeData)
        {
          store.state.routeData = routeData
          return import('../views/stationview.vue')
        }
        else
          return import('../views/index.vue')
      }
    },
    meta: {
      title: 'DJ Streaming Thailand - สถานีวิทยุทั่วประเทศไทย ฟังวิทยุออนไลน์ 24 ชั่วโมง',
    }
  },
  {
    path: '/enroll',
    name: 'enroll',
    component: () => import('../views/mystation/main.vue'),
    children: [
      { 
        path: '',
        meta: {title : "ลงทะเบียนวิทยุออนไลน์"},
        component: () => import('../views/mystation/enroll.vue')
      },
    
    ]
  },
  {
    path: '/mystation',
    name: 'mystation',
    meta: {
      title : "รายการสถานีของฉัน",
      requiresLogin: true
    },
    component: () => import('../views/mystation/main.vue'),
    children: [
      {
        path: '',
        component: () => import('../views/mystation/list.vue')
      },
      { 
        path: ':id',
        meta: {title : "ตั้งค่าสถานี"},
        component: () => import('../views/mystation/enroll.vue')
      },
      { 
        path: ':id/buildapp/android',
        meta: {title : "สร้างแอปแอนดรอยด์"},
        component: () => import('../views/mystation/buildapp.vue')
      },
      { 
        path: ':id/domain',
        meta: {title : "ตั้งค่าโดเมน"},
        component: () => import('../views/mystation/domain.vue')
      },
    ]
  },
  {
    path: '/s/:id',
    name: 'stationview', // อย่าเปลี่ยนนะจ๊ะ ใช้ ref กับ header อยู่
    meta: {
      title : "สถานีวิทยุออนไลน์",
    },
    component: () => import('../views/stationview.vue'),
  },
  {
    path: '/discover/:id',
    name: 'discover',
    meta: {
      title : "สำรวจสถานี",
    },
    beforeEnter: (to, from, next) => {
      if(typeof genre[to.params.id] !== "undefined")
      {
        next()
      }
      else next("/404")
    },
    component: () => import('../views/discover.vue'),
  },

  {
    path: '/client',
    name: 'Client Area',
    meta: {
      title : "Client Area",
    },
    children: [
      {
        path: 'billing',
        component: () => import('../views/client/billing.vue'),
      }
    ],
    component: () => import('../views/client/main.vue'),
  },
  {
    path: '/discoverall',
    name: 'allstations',
    meta: {
      title : "ดูสถานีทั้งหมด",
    },
    component: () => import('../views/discover.vue'),
  },
  {
    path: '/charts',
    name: 'charts', // ห้ามเปลี่ยนไม่งั้นพัง
    meta: {
      title : "THAILAND TOP 100"
    },
    component: () => import('../views/charts.vue')
  },
  //// info page zone start
  {
    path: '/whyus',
    name: 'Why us', // ห้ามเปลี่ยนไม่งั้นพัง
    meta: {title : "เข้าร่วมกับ DJ Streaming ดีอย่างไร"},
    component: () => import('../views/info/whyus.vue')
  },
  {
    path: '/contactus',
    name: 'Contact Us', // ห้ามเปลี่ยนไม่งั้นพัง
    meta: {title : "ติดต่อเรา"},
    component: () => import('../views/info/contact.vue')
  },
  {
    path: '/graphic',
    name: 'Graphic',
    meta: {title : "บริการรับออกแบบโลโก้และรูปภาพสถานี"},
    component: () => import('../views/info/graphic.vue')
  },
  {
    path: '/application',
    name: 'application',
    meta: {title : "บริการสร้างแอปพลิเคชันสำหรับฟังวิทยุออนไลน์บน Android พร้อมอัพโหลดขึ้น Google Play Store"},
    component: () => import('../views/info/application.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    meta: {title : "นโยบายความเป็นส่วนตัว"},
    component: () => import('../views/info/privacy.vue')
  },
  //// info page zone end

  { path: '/:pathMatch(.*)', component: page404 ,meta: {
      title : "Oops! This Page is Not Found."}
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
trackRouter(router);
export default router
