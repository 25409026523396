window.domain = (process.env.NODE_ENV === "development") ? "http://localhost:8080" : "https://www.dj.in.th"
import { createApp } from 'vue'
import App from './App.vue'
import router from './ecosystem/router'
import store from './ecosystem/vuex'
import myMixin from './ecosystem/globalfunction'
import firebase from "firebase/app";
import VueGtag from "vue-gtag-next";
import "firebase/analytics";
const firebaseConfig = {
    apiKey: "AIzaSyAJjKcYsNzIrJEPkK9R0PARBDjmEZrBWoY",
    authDomain: "vipze.app",
    databaseURL: "https://djinth.firebaseio.com",
    projectId: "vipzeapp",
    storageBucket: "vipzeapp.appspot.com",
    messagingSenderId: "148784633066",
    appId: "1:148784633066:web:eba867d0aa43f5dd03f393",
    measurementId: "G-TBWECPGD9G"
};
firebase.initializeApp(firebaseConfig)
firebase.auth().languageCode = 'th'
firebase.analytics()
const app = createApp(App)
app.config.productionTip = false;
app.use(store)
app.use(router)
app.mixin(myMixin)
app.use(VueGtag, {
  property: {
    id: "UA-49113723-1"
  }
})
app.mount('#app')
router.beforeEach(async (to, from, next) => {
    document.title = to.meta.title
    if(to.path!=="/" && window.location.hostname!=="www.dj.in.th" && process.env.NODE_ENV !== "development") // กรณีโดเมนพิเศษเปลี่ยนหน้า
      window.location.href = window.domain+to.fullPath
    else
    // if (
    // to.meta.requiresLogin === true && // ถ้าเพจบังคับ login
    // store.state.userData === false &&  // ถ้ายังไม่ได้ login
    // store.state.userInit === true // ถ้าระบบ user พร้อมใช้งานแล้ว
    // )
    // next("/login")
    // else 
    next()
})