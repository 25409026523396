const stationgenre = 
{
    "all": "ทุกแนวเพลง",
    "hit": "เพลงฮิต",
    "inter": "เพลงสากล",
    "new": "เพลงใหม่",
    "old": "เพลงเก่า",
    "esan": "เพลงอีสาน",
    "life": "เพลงเพื่อชีวิต",
    "looktung": "เพลงลูกทุ่ง",
    "cult": "ศาสนา",
    "news": "ข่าว",
}
export default stationgenre

